import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import DocumentRoutes from 'src/app/pages/document/data-access/enums/document-routes';
import { GetAllDocumentWelderCertificatesAboutToBeRenewedByTenantIdResponse } from 'src/app/shared/data-access/models/server-requests-responses.model';
import { TableColumnMetadata } from 'src/app/shared/ui/core/table/table-column-metadata';
import { TableColumnType } from 'src/app/shared/ui/core/table/table.component';

@Injectable({
	providedIn: 'root'
})
export class DocumentWelderCertificateAboutToBeRenewedTableMetadataService {
	constructor(private translateService: TranslateService) {}

	public getMetadata(): TableColumnMetadata<GetAllDocumentWelderCertificatesAboutToBeRenewedByTenantIdResponse>[] {
		return [
			{
				header: this.translateService.instant('GENERAL.TITLE.LABEL'),
				linkAltText: 'PAGE.DOC_WELDER.TABLE.LINK.ALT',
				field: (documentWelderCertificate: GetAllDocumentWelderCertificatesAboutToBeRenewedByTenantIdResponse) =>
					documentWelderCertificate.title,
				sortColumn: 'title',
				renderType: TableColumnType.TEXT,
				isLink: true,
				linkPath: `${DocumentRoutes.DOCUMENT}/${DocumentRoutes.WELDER_CERTIFICATE_LIST}`,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('PAGE.EMPLOYEE.TITLE.SINGULAR'),
				field: (documentWelderCertificate: GetAllDocumentWelderCertificatesAboutToBeRenewedByTenantIdResponse) =>
					documentWelderCertificate.employee?.initials,
				sortColumn: 'employee.initials',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.RENEWAL.ON.LABEL'),
				field: (documentWelderCertificate: GetAllDocumentWelderCertificatesAboutToBeRenewedByTenantIdResponse) =>
					documentWelderCertificate.renewalDueDate,
				sortColumn: 'renewalDueDate',
				renderType: TableColumnType.DATE,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.DAYS.UNTIL.RENEWAL.LABEL'),
				field: (documentWelderCertificate: GetAllDocumentWelderCertificatesAboutToBeRenewedByTenantIdResponse) =>
					documentWelderCertificate.daysUntilRenewalDueDate,
				sortColumn: 'daysUntilRenewalDueDate',
				renderType: TableColumnType.NUMBER,
				decimalCount: 0,
				isSortable: true,
				defaultSortField: true,
				isVisible: true
			}
		];
	}
}
