<app-load-spinner [fullScreen]="true"></app-load-spinner>

<app-accordion>
	<div class="row">
		<!-- Active Quotes Accordion Item -->
		<div class="col-xl-6" *ngIf="this.authenticationService.isAuthorized(this.quoteReadPermissions)">
			<app-accordion-item
				class="table-container"
				[title]="'PAGE.DASHBOARD.ACTIVE.QUOTES.TITLE' | translate : {total: this.activeQuotes.length.toString(), own: getOwnActiveQuotes()}"
			>
				<app-table
					[tableColumns]="this.quoteAllActiveTableMetadata"
					[data]="this.activeQuotes"
					[showKebabMenu]="false"
					[maxVisibleRows]="this.maxVisibleRows"
					[rowsPerPageOptions]="this.rowsPerPageOptions"
					[exportPermissions]="this.dashboardExportPermissions"
					[defaultSortOrder]="-1"
				></app-table>
			</app-accordion-item>
		</div>

		<!-- Awaiting Customer Quotes Accordion Item -->
		<div class="col-xl-6" *ngIf="this.authenticationService.isAuthorized(this.quoteReadPermissions)">
			<app-accordion-item
				class="table-container"
				[title]="'PAGE.DASHBOARD.AWAITING.CUSTOMER.QUOTES.TITLE' | translate : {total: this.awaitingCustomerQuotes.length.toString(), own: getOwnAwaitingCustomerQuotes()}"
			>
				<app-table
					[tableColumns]="this.quoteAllAwaitingCustomerTableMetadata"
					[data]="this.awaitingCustomerQuotes"
					[showKebabMenu]="false"
					[maxVisibleRows]="this.maxVisibleRows"
					[rowsPerPageOptions]="this.rowsPerPageOptions"
					[exportPermissions]="this.dashboardExportPermissions"
					[defaultSortOrder]="-1"
				></app-table>
			</app-accordion-item>
		</div>

		<!-- Personal Contact Logs Accordion Item -->
		<div class="col-xl-6" *ngIf="this.authenticationService.isAuthorized(this.dashboardContactLogsReadPermissions)">
			<app-accordion-item
				class="table-container"
				[title]="'PAGE.DASHBOARD.CONTACT.LOG.PERSONAL.TITLE' | translate : {total: this.personalActiveContactLogs.length.toString()}"
			>
				<app-table
					[tableColumns]="this.contactLogPersonalTableMetadata"
					[data]="this.personalActiveContactLogs"
					[showKebabMenu]="false"
					[maxVisibleRows]="this.maxVisibleRows"
					[rowsPerPageOptions]="this.rowsPerPageOptions"
					[exportPermissions]="this.dashboardExportPermissions"
					(fieldValueLinkEvent)="this.navigateToContactLogPage($event)"
				></app-table>
			</app-accordion-item>
		</div>

		<!-- Unpersonal Contact Logs Accordion Item -->
		<div class="col-xl-6" *ngIf="this.authenticationService.isAuthorized(this.dashboardContactLogsAllReadPermissions)">
			<app-accordion-item
				class="table-container"
				[title]="'PAGE.DASHBOARD.CONTACT.LOG.UNPERSONAL.TITLE' | translate : {total: this.othersActiveContactLogs.length.toString()}"
			>
				<app-table
					[tableColumns]="this.contactLogOthersTableMetadata"
					[data]="this.othersActiveContactLogs"
					[showKebabMenu]="false"
					[maxVisibleRows]="this.maxVisibleRows"
					[rowsPerPageOptions]="this.rowsPerPageOptions"
					[exportPermissions]="this.dashboardExportPermissions"
					(fieldValueLinkEvent)="this.navigateToContactLogPage($event)"
				></app-table>
			</app-accordion-item>
		</div>

		<!-- Active Projects Accordion Item -->
		<div class="col-xl-6" *ngIf="this.authenticationService.isAuthorized(this.projectReadPermissions)">
			<app-accordion-item
				class="table-container"
				[title]="'PAGE.DASHBOARD.ACTIVE.PROJECTS.TITLE' | translate : {total: this.activeProjects.length.toString()}"
			>
				<app-table
					[tableColumns]="this.projectAllActiveTableMetadata"
					[data]="this.activeProjects"
					[showKebabMenu]="false"
					[maxVisibleRows]="this.maxVisibleRows"
					[rowsPerPageOptions]="this.rowsPerPageOptions"
					[exportPermissions]="this.dashboardExportPermissions"
					[defaultSortOrder]="-1"
				></app-table>
			</app-accordion-item>
		</div>

		<!-- Active Purchases Accordion Item -->
		<div class="col-xl-6" *ngIf="this.authenticationService.isAuthorized(this.purchaseReadPermissions)">
			<app-accordion-item
				class="table-container"
				[title]="'PAGE.DASHBOARD.ACTIVE.PURCHASES.TITLE' | translate : {total: this.activePurchases.length.toString(), own: getOwnPurchases()}"
			>
				<app-table
					[tableColumns]="this.purchaseActiveTableMetadata"
					[data]="this.activePurchases"
					[showKebabMenu]="false"
					[maxVisibleRows]="this.maxVisibleRows"
					[rowsPerPageOptions]="this.rowsPerPageOptions"
					[exportPermissions]="this.dashboardExportPermissions"
				></app-table>
			</app-accordion-item>
		</div>

		<!-- Welder Certificates About to Expire Accordion Item -->
		<div
			class="col-xl-6"
			*ngIf="this.authenticationService.isAuthorized(this.dashboardWelderCertificateReadPermissions)"
		>
			<app-accordion-item
				class="table-container"
				[title]="'PAGE.DASHBOARD.ABOUT.TO.EXPIRE.WELDER.QUOTES.TITLE' | translate : {total: this.aboutToExpireDocumentWelderCertificates.length.toString()}"
			>
				<app-table
					[tableColumns]="this.documentWelderCertificateAboutToFinallyExpireTableMetadata"
					[data]="this.aboutToExpireDocumentWelderCertificates"
					[showKebabMenu]="false"
					[maxVisibleRows]="this.maxVisibleRows"
					[rowsPerPageOptions]="this.rowsPerPageOptions"
					[exportPermissions]="this.dashboardExportPermissions"
				></app-table>
			</app-accordion-item>
		</div>

		<!-- Active DrawingValidations Accordion Item -->
		<div class="col-xl-6" *ngIf="this.authenticationService.isAuthorized(this.drawingValidationReadPermissions)">
			<app-accordion-item
				class="table-container"
				[title]="'PAGE.DASHBOARD.ACTIVE.DRAWING.VALIDATIONS.TITLE' | translate : {total: this.activedrawingValidations.length.toString(), own: getOwnDrawingValidations()}"
			>
				<app-table
					[tableColumns]="this.drawingValidationActiveTableMetadata"
					[data]="this.activedrawingValidations"
					[showKebabMenu]="false"
					[defaultSortOrder]="-1"
					[maxVisibleRows]="this.maxVisibleRows"
					[rowsPerPageOptions]="this.rowsPerPageOptions"
					[exportPermissions]="this.dashboardExportPermissions"
				></app-table>
			</app-accordion-item>
		</div>

		<!-- Welder Certificates About to be renewed Accordion Item -->
		<div
			class="col-xl-6"
			*ngIf="this.authenticationService.isAuthorized(this.dashboardWelderCertificateReadPermissions)"
		>
			<app-accordion-item
				class="table-container"
				[title]="'PAGE.DASHBOARD.ABOUT.TO.BE.RENEWED.WELDER.QUOTES.TITLE' | translate : {total: this.aboutToBeRenewedDocumentWelderCertificates.length.toString()}"
			>
				<app-table
					[tableColumns]="this.documentWelderCertificateAboutToBeRenewedTableMetadata"
					[data]="this.aboutToBeRenewedDocumentWelderCertificates"
					[showKebabMenu]="false"
					[maxVisibleRows]="this.maxVisibleRows"
					[rowsPerPageOptions]="this.rowsPerPageOptions"
					[exportPermissions]="this.dashboardExportPermissions"
				></app-table>
			</app-accordion-item>
		</div>
	</div>
</app-accordion>
