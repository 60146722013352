import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import ProjectRoutes from 'src/app/pages/project/data-access/enums/project-routes';
import { GetAllActiveProjectsByTenantIdResponse } from 'src/app/shared/data-access/models/server-requests-responses.model';
import { UserInformationService } from 'src/app/shared/data-access/services/user-information/user-information.service';
import { TableColumnMetadata } from 'src/app/shared/ui/core/table/table-column-metadata';
import { TableColumnType } from 'src/app/shared/ui/core/table/table.component';

@Injectable({
	providedIn: 'root'
})
export class ProjectAllActiveTableMetadataService {
	constructor(
		private userInformationService: UserInformationService,
		private translateService: TranslateService
	) {}

	public getMetadata(): TableColumnMetadata<GetAllActiveProjectsByTenantIdResponse>[] {
		return [
			{
				header: this.translateService.instant('GENERAL.NAME.LABEL'),
				linkAltText: 'PAGE.PROJECT.TABLE.LINK.ALT',
				field: (project: GetAllActiveProjectsByTenantIdResponse) => project.projectNumber,
				sortColumn: 'projectNumber',
				renderType: TableColumnType.TEXT,
				isLink: true,
				linkPath: `${ProjectRoutes.PROJECT}/${ProjectRoutes.PROJECT_ALL_LIST}`,
				isSortable: true,
				defaultSortField: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('PAGE.QUOTE.TITLE'),
				field: (project: GetAllActiveProjectsByTenantIdResponse) => project.quote?.quoteNumber,
				sortColumn: 'quote.quoteNumber',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('PAGE.COMPANY_CONTACT.TITLE.SINGULAR'),
				field: (project: GetAllActiveProjectsByTenantIdResponse) => project.quote?.companyContact?.title,
				sortColumn: 'quote.companyContact.title',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: true
			},
			{
				header: this.translateService.instant('GENERAL.STATUS.LABEL'),
				field: (project: GetAllActiveProjectsByTenantIdResponse) => project.projectStatus?.titleDanish,
				sortColumn: 'projectStatus.titleDanish',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: this.userInformationService.usesDanishTranslation(),
				exportable: this.userInformationService.usesDanishTranslation()
			},
			{
				header: this.translateService.instant('GENERAL.STATUS.LABEL'),
				field: (project: GetAllActiveProjectsByTenantIdResponse) => project.projectStatus?.titleEnglish,
				sortColumn: 'projectStatus.titleEnglish',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: this.userInformationService.usesEnglishTranslation(),
				exportable: this.userInformationService.usesEnglishTranslation()
			},
			{
				header: this.translateService.instant('GENERAL.STATUS.LABEL'),
				field: (project: GetAllActiveProjectsByTenantIdResponse) => project.projectStatus?.titleGerman,
				sortColumn: 'projectStatus.titleGerman',
				renderType: TableColumnType.TEXT,
				isSortable: true,
				isVisible: this.userInformationService.usesGermanTranslation(),
				exportable: this.userInformationService.usesGermanTranslation()
			}
		];
	}
}
